<template>
  <div class="products-grid">
    <div
      id="products"
      :class="[
        'products-grid__items',
        {
          'products-grid__items--one-columns': maxGridColumns === 1,
          'products-grid__items--two-columns': maxGridColumns === 2,
          'products-grid__items--three-columns': maxGridColumns === 3,
          'products-grid__items--four-columns': maxGridColumns === 4,
          'products-grid__items--mobile-one-columns':
            mobileMaxGridColumns === 1,
          'products-grid__items--mobile-two-columns':
            mobileMaxGridColumns === 2,
          'products-grid__items--mobile-three-columns':
            mobileMaxGridColumns === 3,
          'products-grid__items--mobile-four-columns':
            mobileMaxGridColumns === 4,
        },
      ]"
    >
      <product
        v-for="(p, index) in products"
        :key="p.id"
        :product="p"
        :catalog-has-badge="catalogHasBadge"
        :class="[
          'products-grid__item',
          alternatedCssClasses(index),
          { 'catalog__item--goforgood': goForGoodCatalog },
        ]"
        :type="'catalogProduct'"
        :case-device-id="caseDeviceId"
        :send-gtm-data="true"
        :device-slug="deviceSlug"
        :show-sold-off="showSoldOff"
        :show-all-colors="allowAllColors"
      />
    </div>
  </div>
</template>

<script>
import pushDataLayer from '~/mixins/gtm/push-data-layer'
import isPresent from '~/utils/isPresent'
import Product from '~/components/product/Product.vue'
import Ga4Parser from '~/utils/Ga4Parser'

export default {
  name: 'ProductsGrid',
  components: {
    Product,
  },
  mixins: [pushDataLayer],
  props: {
    taxonSlug: {
      type: String,
      required: false,
      default: '',
    },
    productFilter: {
      type: String,
      required: false,
      default: '',
    },
    caseDeviceId: {
      type: [Number, String],
      default: null,
    },
    caseScope: {
      type: Boolean,
      default: true,
    },
    products: {
      type: Array,
      default: () => [],
    },
    maxGridColumns: {
      type: Number, // (2, 3, 4)
      default: 3,
    },
    mobileMaxGridColumns: {
      type: Number, // (2, 3, 4)
      default: 2,
    },
    sendGtmData: {
      type: Boolean,
      default: false,
    },
    deviceSlug: {
      type: String,
      default: '',
    },
    goForGoodCatalog: {
      type: Boolean,
      default: false,
    },
    showSoldOff: {
      type: Boolean,
      default: false,
    },
    allowAllColors: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    catalogHasBadge() {
      const productsWithBadge = this.products.filter((product) =>
        this.isPresent(product.badge)
      )
      return productsWithBadge.length > 0
    },
    insiderItems() {
      // https://academy.useinsider.com/docs/insider-object-integration-listing-object

      return this.products.map((prod) => ({
        id: prod.id,
        name: prod.name,
        taxonomy: [prod.product_type_code],
        currency: this.$store.state.currency,
        unit_price: prod.old_price || prod.price,
        unit_sale_price: prod.price,
        url: `${window.location.origin}/${prod.slug}/p`,
        product_image_url: prod.image,
      }))
    },
  },
  watch: {
    products() {
      if (this.sendGtmData) this.sendToGtm()
    },
  },

  mounted() {
    if (this.sendGtmData) this.sendToGtm()
  },
  methods: {
    isPresent,
    alternatedCssClasses(index) {
      const cssClasses = []
      if (index % 2 === 0) cssClasses.push('catalog__first-item-mobile')
      if (index % 3 === 0)
        cssClasses.push(
          'catalog__first-item-tablet',
          'catalog__first-item-desktop'
        )
      return cssClasses
    },
    sendToGtm() {
      const formattedProducts = this.products.map((product) => {
        return {
          id: product.id,
          name: product.name,
        }
      })

      const ga4Items = new Ga4Parser(this.$store.state).viewItemList(
        this.products.map((product) => ({
          ...product,
          product_id: product.id,
          case_device_id: this.caseDeviceId,
        }))
      )

      const ecommerce = new Ga4Parser(this.$store.state).viewItemListV2(
        this.products.map((product) => ({
          ...product,
          product_id: product.id,
          case_device_id: this.caseDeviceId,
        }))
      )

      /* 
        Prevent Side Effect since ga4EventValue is an array, 
        gtm automaticly append a new item instead of create a new array  
      */
      this.pushDataLayer({
        ga4EventValue: undefined,
      })

      this.pushDataLayer({
        event: 'genericEvent',
        eventCategory: 'navigation',
        eventAction: 'productImpression',
        ecommerce,
        eventValue: formattedProducts,
        ga4EventValue: ga4Items,
        insiderEventValue: this.insiderItems,
      })
    },
  },
}
</script>

<style lang="scss">
.products-grid {
  .products-grid__items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3 * $v4_gutter;
    margin-bottom: 5 * $v4_gutter;
  }

  @media (min-width: 451px) {
    .products-grid__items--one-columns {
      grid-template-columns: 1fr;
    }

    .products-grid__items--two-columns {
      grid-template-columns: 1fr 1fr;
    }

    .products-grid__items--three-columns {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .products-grid__items--four-columns {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  @media (max-width: 450px) {
    .products-grid__items--mobile-one-columns {
      grid-template-columns: 1fr;
    }

    .products-grid__items--mobile-two-columns {
      grid-template-columns: 1fr 1fr;
    }

    .products-grid__items--mobile-three-columns {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .products-grid__items--mobile-four-columns {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  .products-grid__item {
    min-width: 0;
  }

  .catalog__item--goforgood {
    width: 100%;
    margin-bottom: 40px;

    .catalog__item-box {
      background-color: #005baa;
      padding: 30px;
      box-sizing: border-box;
      position: relative;
      color: white;

      &::before {
        content: '';
        width: 0;
        height: 0;
        border-top: 18px solid white;
        border-right: 25px solid transparent;
        border-left: 25px solid transparent;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      strong {
        font-size: 28px;
        line-height: normal;
        margin-bottom: 20px;
      }

      p {
        font-size: 14px;
        line-height: normal;
      }
    }
  }

  @media (min-width: $v4_desktop) {
    .products-grid__items {
      grid-gap: 8 * $v4_gutter;
    }

    .catalog__item--goforgood {
      display: flex;
      padding: 0 15px;
      box-sizing: border-box;

      &:nth-child(4n + 1) {
        .catalog__item-box {
          background-color: $v4_color_primary;
        }
      }
      &:nth-child(4n + 4) {
        .catalog__item-box {
          background-color: $v4_color_primary;
        }
      }

      .catalog__item-link {
        width: 42%;
      }

      .catalog__item-box {
        flex-shrink: 0;
        width: 58%;

        &::before {
          border-top: 25px solid transparent;
          border-right: 18px solid transparent;
          border-bottom: 25px solid transparent;
          border-left: 18px solid white;
          position: absolute;
          top: 44px;
          left: 0;
          transform: translate(0);
        }

        strong {
          font-size: 28px;
        }
        p {
          font-size: 18px;
        }
      }
    }
  }
}
</style>
